body {
  * {
    font-family: 'Poppins';
  }
  iframe {
    display: none !important;
  }
  p {
    color: #000000;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}  
.easycarloan {
  .wrap-content-top {
    .wrap-logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 40px;
      .logo2 {
        img {
          max-width: 180px;
        }
      }
    }
  }
  .title {
    margin-bottom: 30px;
    text-align: center;
    color: #000000;
    font-weight: 700;
    font-size: 30px;
  }
  .light-line {
    color: #3f80c1;
  }
  .footer {
    padding: 50px 0;
    background-color: #e3f1ff;
    .nav-item {
      margin-bottom: 20px;
      .nav-link {
        font-size: 15px;
        font-weight: 700;
        padding: 5px 15px;
        color: #3f80c1;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    p, a {
      color: #000000;
      font-size: 12px;
      text-align: justify;
      text-align-last: center;
      line-height: 1.6;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .accordion {
    padding: 0 0 50px;
    .card {
      border: 0;
      .card-header {
        cursor: pointer;
        color: #000000;
        font-weight: 500;
        border: 0;
        padding: 0 0 15px 0;
        background-color: transparent;
        border-bottom: 1px solid #3f80c1;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        img {
          max-width: 20px;
          position: relative;
          margin-left: 30px;
          top: -3px;
        }
      }
    }
    .card-body {
      padding: 0 0 15px 0;
      p {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .bankLogo {
    padding: 60px 0;
  }
  .title-small {
    margin-bottom: 20px;
    text-align: left;
    color: #000000;
    font-weight: 700;
    font-size: 18px;
  }
  .home-page {
    background-color: #e3f1ff;
    padding: 60px 0;
    margin-bottom: 60px;
    .title {
      margin-bottom: 50px;
    }
    .item {
      text-align: center;
      padding: 0 15px;
      img {
        max-width: 150px;
        margin: 0 auto 20px auto;
      }
      h2 {
        color: #3f80c1;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }
  .wrap-form {
    padding: 0 0 50px 0;
    background-image: url("../../pages/Home/assets/images/bg-content.png");
    background-position: center top -100px;
    background-size: 1300px;
    background-repeat: no-repeat;
    min-height: 780px;
    position: relative;
    .rating {
      position: absolute;
      bottom: 40px;
      width: 100%;
      left: 0;
    }
    .title-header {
      font-size: 45px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 40px;
      span {
        font-weight: 600;
        color: #3f80c1;
      }
    }
    .from {
      max-width: 450px;
      margin: 0 auto;
      .invalid-feedback {
        font-size: 0.9rem;
        font-weight: 500;
      }
      .title-form {
        text-align: center;
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 25px;
      }
      .col-6,
      .col-12 {
        padding: 0;
      }
      .row {
        margin: 0;
      }
      .item-box {
        border: 1px solid #a0a0a0;
        padding: 10px 15px;
        text-align: center;
        border-radius: 6px;
        margin: 6px;
        transition: all 0.3s;
        background-color: #fff;
        &:hover,
        &.active {
          background-color: #3f80c1;
          border-color: #3f80c1;
          a {
            color: #ffffff;
          }
        }
        a, &.submit {
          display: block;
          text-decoration: none;
          transition: all 0.3s;
          font-weight: 600;
          color: #3f80c1;
          text-transform: uppercase;
        }
        &.submit {
          cursor: pointer;
          color: #ffffff;
        }
      }
    }
  }
  .rating {
    display: flex;
    align-items: center;
    justify-content: center;
    .rate1 {
      max-width: 150px;
    }
    .rate2 {
      max-width: 140px;
      position: relative;
      top: 3px;
    }
    .rate3 {
      max-width: 100px;
      position: relative;
      top: 7px;
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 991px) {
    .wrap-content-top {
      .wrap-logo {
        padding: 20px 15px;
        .logo1 {
          img {
            max-width: 160px;
          }
        }
        .logo2 {
          img {
            max-width: 140px;
          }
        }
      }
    }
    .title {
      margin-bottom: 20px;
      font-size: 25px;
    }
    .accordion {
      padding: 0 0 30px;
      .card {
        .card-header {
          img {
            max-width: 15px;
            top: -2px;
          }
        }
      }
    }
  
    .bankLogo {
      padding: 40px 0;
    }
    .title-small {
      margin-bottom: 15px;
    }
    .home-page {
      padding: 40px 0;
      margin-bottom: 40px;
      .title {
        margin-bottom: 30px;
      }
      .item {
        padding: 0;
      }
    }
    .wrap-form {
      background-position: center bottom 36px;
      background-size: contain;
      min-height: 660px;
      position: relative;
      .title-header {
        font-size: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .wrap-content-top {
      .wrap-logo {
        .logo1 {
          img {
            max-width: 120px;
          }
        }
        .logo2 {
          img {
            max-width: 100px;
          }
        }
      }
    }
    .title {
      margin-bottom: 20px;
      font-size: 20px;
    }
    .accordion {
      padding: 0 0 30px;
      .card {
        .card-header {
          img {
            max-width: 15px;
            top: -2px;
          }
        }
      }
    }
    .home-page {
      padding: 40px 0;
      margin-bottom: 40px;
      .title {
        margin-bottom: 30px;
      }
      .item {
        padding: 0 15px;
        margin-bottom: 30px;
        p {
          text-align: justify;
          text-align-last: center;
        }
        img {
          margin-bottom: 10px;
        }
      }
    }
    .wrap-form {
      background-position: center bottom 36px;
      background-size: contain;
      min-height: 500px;
      position: relative;
      .title-header {
        font-size: 26px;
        letter-spacing: -0.03rem;
        line-height: 1.4;
        margin-bottom: 20px;
        span {
          display: block;
        }
      }
      .from {
        padding: 0 15px;
        .title-form {
          font-size: 20px;
          margin-bottom: 20px;
        }
        a {
          font-size: 18px;
        }
      }
      .rating {
        .rate1 {
          max-width: 100px;
        }
        .rate2 {
          max-width: 100px;
          top: 3px;
        }
        .rate3 {
          max-width: 80px;
          top: 5px;
          margin-left: 5px;
        }
      }
    }
    .accordion .card .card-header {
      font-size: 18px;
      letter-spacing: -0.05rem;
    }
    .footer {
      padding: 30px 0;
      .nav-item {
        margin-bottom: 20px;
        .nav-link {
          font-size: 12px;
          padding: 5px;
        }
      }
    }
  }
}

